<div class="row footer text-center">
  <div class="col-2"></div>
  <div class="col-8 pr-0">
    <p class="subtitle blue no-opacity">Visítanos en</p>
    <p class="opacity">
      Presidente Masaryk 111-5, Colonia Polanco V sección, Delegación Miguel
      Hidalgo
    </p>
    <p class="subtitle blue no-opacity">Contáctanos</p>
    <p class="opacity">
      Teléfono: 8000 832 970
      <span class="d-block">Correo Electrónico:
        <a href="atencionaclientes@uniclick.mx" class="blue">atencionaclientes@uniclick.mx</a></span>
      <span class="d-block">Página de internet:
        <a href="https://www.uniclick.com.mx" class="blue">www.uniclick.com.mx</a>
      </span>
    </p>
    <p>
      <span class="opacity">Consulta nuestro </span>
      <a href="https://uniclick.com.mx/aviso-de-privacidad" target="_blank" class="blue">Aviso de privacidad</a>
      y
      <a href="https://uniclick.com.mx/terms-conditions" target="_blank" class="blue">Términos y Condiciones</a>
      <span class="opacity d-block">
        UNE: Unidad Especializada de atención a clientes.</span>
    </p>
    <div class="row pl-3 d-flex justify-content-center">
      <a href="https://www.facebook.com/UniclickByUnifin/" target="_blank" class="mr-2">
        <img class="social-img" src="../../assets/img/social/facebook.webp" alt="facebook" />
      </a>
      <a href="https://www.twitter.com/Uniclick_Mx" target="_blank" class="mr-2">
        <img class="social-img" src="../../assets/img/social/twitter.webp" alt="twitter" />
      </a>
      <a href="https://www.instagram.com/uniclick_mx/" target="_blank" class="mr-2">
        <img class="social-img" src="../../assets/img/social/instagram.webp" alt="instagram" />
      </a>
      <a href="https://www.linkedin.com/company/uniclickbyunifin/?viewAsMember=true" target="_blank" class="mr-2">
        <img class="social-img" src="../../assets/img/social/linkedin.webp" alt="linkedin" />
      </a>
    </div>
  </div>
  <div class="col-2"></div>
</div>

<div class="row footer-mobile">
  <div class="col-12 px-5 my-4">
    <p class="subtitle blue no-opacity">Visítanos en</p>
    <p class="opacity">
      Presidente Masaryk 111-5, Colonia Polanco V sección, 
      <span class="d-block">Delegación Miguel Hidalgo</span>
    </p>
    <p class="subtitle blue no-opacity">Contáctanos</p>
    <p class="opacity">
      Teléfono: 8000 832 970
      <span class="d-block">Correo Electrónico:
        <a href="https://www.unifin.com.mx/aviso-de-privacidad.php" target="_blank"
          class="blue">atencionaclientes@uniclick.mx</a></span>
      <span class="d-block">Página de internet:
        <a href="https://www.uniclick.com.mx" class="blue">www.uniclick.com.mx</a>
      </span>
    </p>
    <p>
      <span class="opacity">Consulta nuestro </span>
      <a href="https://uniclick.com.mx/aviso-de-privacidad" target="_blank" class="blue">Aviso de privacidad</a>
      y
      <a href="https://uniclick.com.mx/terms-conditions" target="_blank" class="blue">Términos y Condiciones</a>
      <span class="opacity">
        UNE: Unidad Especializada de atención a clientes.</span>
    </p>
  </div>
  <div class="col-12 px-5 my-4">
    <div class="row pl-3">
      <a href="https://www.facebook.com/UniclickByUnifin/" target="_blank" class="mr-2">
        <img class="social-img" src="../../assets/img/social/facebook.webp" alt="facebook" />
      </a>
      <a href="https://www.twitter.com/Uniclick_Mx" target="_blank" class="mr-2">
        <img class="social-img" src="../../assets/img/social/twitter.webp" alt="twitter" />
      </a>
      <a href="https://www.instagram.com/uniclick_mx/" target="_blank" class="mr-2">
        <img class="social-img" src="../../assets/img/social/instagram.webp" alt="instagram" />
      </a>
      <a href="https://www.linkedin.com/company/uniclickbyunifin/?viewAsMember=true" target="_blank" class="mr-2">
        <img class="social-img" src="../../assets/img/social/linkedin.webp" alt="linkedin" />
      </a>
    </div>
  </div>
</div>

<div class="row sub-footer">
  <div class="col-1"></div>
  <div class="col-10">
    <span class="d-block text-center opacity my-2">
      En Uniclick no pedimos pagos anticipados para otorgar nuestro Crédito
      Simple.
    </span>
    <span class="d-block text-center opacity my-2">
      Costo Anual Total (CAT) PROMEDIO de financiamiento expresado en términos
      porcentuales anuales sin IVA 46.4%, para fines informativos y de
      comparación, incorpora la totalidad de los costos y gastos inherentes a
      los créditos incluyendo una comisión única por apertura de hasta el 5%.
      Fecha de cálculo: 18 de noviembre de 2020, vigente hasta el 18 de mayo de
      2021. Tasa de interés fija anual ponderada por saldo de 33% sin IVA, sobre
      saldos insolutos.
    </span>

    <span class="d-block text-center opacity mb-4">Uniclick y Unifin © 2020 Derechos Reservados</span>
  </div>
  <div class="col-1"></div>
</div>