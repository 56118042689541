<div class="container">
  <!-- First row -->
  <div class="row first-row">
    <div class="col-md-7 col-sm-12 second-row">
      <div class="title">
        Uniclick se une
        <span class="d-block"> a la iniciativa de Suma:</span>
        <span class="d-block"> Pymes digitales México</span>
      </div>

      <div class="subtitle mt-2">
        Solicita tu crédito en línea de hasta 3
        <span class="d-block">millones de pesos y obtén tu autorización</span>
        <span class="d-block">en minutos.</span>
      </div>
    </div>
    <div class="col-md-5 col-sm-12 d-flex justify-content-center multi-product">
      <div class="bg-neu-container">
        <div class="card-neu"></div>
        <div class="card">
          <div class="card-body">
            <form [formGroup]="form" id="credit-form">
              <p class="form-title">
                Solicita y obtén tu crédito
                <span class="d-block">
                  en minutos
                </span>
              </p>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Nombre(s)<span class="red">*</span></mat-label>
                <input matInput type="text" formControlName="name" name="nombre">
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Apellido Paterno<span class="red">*</span></mat-label>
                <input matInput type="text" formControlName="pat" name="paterno">
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Apellido Materno<span class="red">*</span></mat-label>
                <input matInput type="text" formControlName="mat" name="materno">
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Correo<span class="red">*</span></mat-label>
                <input matInput type="text" formControlName="email" name="correo">
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Teléfono (10 dígitos)<span class="red">*</span></mat-label>
                <input matInput type="text" formControlName="phone" name="telefono" maxlength="10">
              </mat-form-field>
              <input matInput type="text" name="leadSource" value="Alianza Microsoft" style="display: none;">
              <div class="d-flex">
                <div>
                  <label class="switch">
                    <input type="checkbox" formControlName="terms_and_conditions" name="switch"
                      class="pantalla1-primerFormulario-input" autocomplete="off" id="terms_and_conditions"/>
                    <span class="slider round"></span>
                  </label>
                </div>

                <div class="texto-aviso">
                  <p>
                    Acepto el Aviso de Privacidad y los Términos y Condiciones de Uniclick
                  </p>
                </div>
              </div>
              <button [disabled]="form.invalid || buttonDisabled" class="blue-button w-100 mb-4 mt-5" name="index-btn-1" id="index-btn-1" (click)="sendToCRM()">
                Solicita tu crédito ahora
                <img src="../../assets/img/right-arrow.svg" alt="right-arrow" class="btn-arrow">
              </button>
            </form>

            <div class="alert alert-primary" role="alert" *ngIf="error === false">
              Felicidades, tu información se ha enviado con éxito
            </div>

            <div class="alert alert-danger" role="alert" *ngIf="false">
              Ha ocurrido un error al enviar tus datos
            </div>

            <!-- <button
              class="btn btn-primary btn-block btn-neu-blue mt-4 py-2"
              [disabled]="form.invalid"
            >
              Solicita tu crédito ahora
            </button> -->
          </div>
        </div>
      </div>
      <img src="../../assets/img/frijol-grandote.png" alt="frijol-grandote" class="frijol-grandote" />
    </div>
  </div>

  <div id="productos"></div>
  <!-- Third row -->
  <div class="row mt-5 productos-row">
    <div class="col-6">
      <img src="../../assets/img/img-productos.png" alt="img-productos" class="img-productos">
    </div>
    <div class="col-lg-6 col-sm-12 productos-column pl-5">
      <div class="title">¿Qué es Uniclick?</div>
      <div class="subtitle mb-4 my-3 our-products">
        Uniclick es una empresa digital respaldada por
        <span class="d-block"> los más de 25 años de experencia y solidez de</span>
        <span class="d-block"> grupo UNIFIN.</span>
        <div class="d-block mt-4">
          El Objetivo de Uniclick es impulsar a los
          <span class="d-block"> negocios en México a través de financiamiento</span>
          <span class="d-block"> seguro y ágil.</span>
        </div>
      </div>
    </div>
  </div>

  <div id="ventajas"></div>
  <!-- Fourth row -->
  <div class="row fourth-row">
    <div class="row">
      <div class="col-12">
        <div class="title text-center">Ventajas Uniclick</div>
        <div class="subtitle text-center mb-4 finance-solution">
          Al obtener una solución financiera con Uniclick estás respaldado por
          Unifin, la financiera
          <span class="d-block">
            independiente con más de 28 años de experiencia en el mercado.
            Además, fusionamos la agilidad del
          </span>
          <span class="d-block">
            mundo digital con la confianza de lo humano.
          </span>
        </div>
      </div>
    </div>
    <div class="row w-100">
      <div class="col-6 d-flex justify-content-center advantage-image">
        <img src="../../assets/img/vector-lanzamiento.png" alt="vector-lanzamiento" class="w-80" />
      </div>
      <div class="col-1"></div>
      <div class="col-md-5 col-sm-12 absolute-center advantage-container">
        <div class="row">
          <div class="col-2 col-img">
            <img src="../../assets/img/time.svg" alt="time" />
          </div>
          <div class="col-10">
            <span class="blue">Rapidez en el proceso</span>
            <span class="d-block opacity" style="font-weight: 400">
              Nuestro proceso es 100%
              <span class="d-block">en línea, por lo cual autorizamos</span>
              <span class="d-block">en minutos</span>
            </span>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-2 col-img">
            <img src="../../assets/img/note.svg" alt="time" />
          </div>
          <div class="col-10">
            <span class="blue">Sin letras chiquitas</span>
            <span class="d-block opacity" style="font-weight: 400">
              Nosotros sí somos claros y
              <span class="d-block">transparentes desde el inicio</span>
            </span>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-2 col-img">
            <img src="../../assets/img/bill.svg" alt="time" />
          </div>
          <div class="col-10">
            <span class="blue">Estamos contigo en todo</span>
            <span class="d-block blue">momento</span>
            <span class="d-block opacity" style="font-weight: 400">
              Atención personalizada
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-5 col-sm-12 absolute-center advantage-container-mobile">
        <div class="row mt-4">
          <div class="col-4 col-img">
            <img src="../../assets/img/time.svg" alt="time" />
          </div>
          <div class="col-8">
            <span class="blue">Rapidez en el proceso</span>
            <span class="d-block opacity" style="font-weight: 400">
              Nuestro proceso es 100% en línea, por lo cual autorizamos en
              minutos
            </span>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-4 col-img">
            <img src="../../assets/img/note.svg" alt="time" />
          </div>
          <div class="col-8">
            <span class="blue">Sin letras chiquitas</span>
            <span class="d-block opacity" style="font-weight: 400">
              Nosotros sí somos claros y transparentes desde el inicio
            </span>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-4 col-img">
            <img src="../../assets/img/bill.svg" alt="time" />
          </div>
          <div class="col-8">
            <span class="blue">Sin penalizaciones</span>
            <span class="d-block opacity" style="font-weight: 400">
              Tú decides cuándo terminar de pagar
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="como-funciona"></div>
  <!-- Fifth row -->
  <div class="row fifth-row">
    <div class="row">
      <div class="col-12">
        <div class="title text-center">¿Cómo funciona?</div>
        <div class="subtitle text-center mb-4 works">
          <span class="bold">Sólo necesitas ser Persona Moral o Persona Física con Actividad
            Empresarial,</span>
          <span class="d-block">haber ejercido actividad 12 meses, contar con tu CIEC del SAT
            y</span>
          <span class="d-block bold">realizar tu solicitud en tres sencillos pasos.</span>
        </div>
      </div>
    </div>

    <div class="row mt-4 steps-row-mobile">
      <div class="col-md-4 col-sm-12 mt-4">
        <div>
          <p class="number">01</p>
          <span class="bar"></span>
          <p class="subtitle mb-0" style="font-weight: 700">
            Registra tus datos
          </p>
          <p class="opacity" style="font-weight: 400">
            y obtén tu autorización
            <span class="d-block">en minutos</span>
          </p>
        </div>
        <img src="../../assets/img/crea-tu-cuenta.png" alt="paso-1" class="w-100 mt-2 register-image" />
      </div>
      <div class="col-md-4 col-sm-12 mt-4">
        <div>
          <p class="number">02</p>
          <span class="bar"></span>
          <p class="subtitle mb-0" style="font-weight: 700">
            Completa tu expediente
          </p>
          <p class="opacity" style="font-weight: 400">
            con los datos solicitados y firma tu contrato
          </p>
        </div>
        <img src="../../assets/img/integra-expediente.png" alt="paso-2" class="w-100 data-image" />
      </div>
      <div class="col-md-4 col-sm-12 mt-4 pl-5">
        <div>
          <p class="number">03</p>
          <span class="bar"></span>
          <p class="subtitle mb-0" style="font-weight: 700">
            ¡Listo! Recibe tu dinero
          </p>
          <p class="opacity m-0 mb-4" style="font-weight: 400">
            para hacer crecer tu empresa
          </p>
        </div>
        <img src="../../assets/img/recibe-tu-dinero.png" alt="paso-3" class="w-100 grow-image" />
      </div>
    </div>
  </div>
</div>
