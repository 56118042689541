import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss']
})
export class BenefitsComponent implements OnInit {

  images = [
    {
      src: '../../assets/img/carrusel/colabora.jpg',
      description: '25% descuento en espacio de trabajo'
    },
    {
      src: '../../assets/img/carrusel/qualtrium.jpg',
      description: '15% de descuento en membresía'
    },
    {
      src: '../../assets/img/carrusel/shopper.jpg',
      description: '40% descuento en plan anual o 15% descuento en plan mensual'
    },
    {
      src: '../../assets/img/carrusel/pedidoscom.jpg',
      description: 'Membresía Pro y precios preferenciales'
    },
    {
      src: '../../assets/img/carrusel/contpaqi.jpg',
      description: '2 meses gratis en licencia de timbrado*'
    },
    {
      src: '../../assets/img/carrusel/edenred.jpg',
      description: '2 meses sin comisión*'
    },
    {
      src: '../../assets/img/carrusel/nomilinea.jpg',
      description: '10% de descuento en licencia anual*'
    },
    {
      src: '../../assets/img/carrusel/worky.jpg',
      description: '1 mes gratis + 30% de descuento en el segundo mes*'
    }];

  customOptions: OwlOptions = {
    loop: true,
    dots: false,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: false,
    responsive: {
      0: {
        items: 1,
        nav: false
      },
      600: {
        items: 2,
        nav: false
      },
      1000: {
        items: 4,
        nav: false
      }
    }
  };

  constructor() { }

  ngOnInit(): void {
  }

}
