
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UnifinGateService {
    constructor(private http: HttpClient) { }

    auth(): Observable<any> {
        const url = 'http://ps.unifin.com.mx:18086/authenticate';
        return this.http.post<any>(url, {
            "username": "LeadsAlianzaUniclick",
            "password": "KLC314LdC514NLU144ZN"
        });
    }

    sendLead(lead: any): Observable<any> {
        const url = 'http://ps.unifin.com.mx:18086/rest/leads/LeadAltaServicios';
        return this.http.post<any>(url, lead);
    }

    // method that connects to unifin gate
    createLead(lead: any): Observable<any> {
        const url = 'http://127.0.0.1:8000/api/lead';
        return this.http.post<any>(url, lead);
    }

    unileads(lead: any): Observable<any> {
      const url = (['suma.uniclick.com.mx'].includes(location.hostname)) ? "https://unilease.uniclick.net/unileads" : "https://unilease.testuniclick.com/unileads";
      return this.http.post<any>(url, lead);
    }
}
