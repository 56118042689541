import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UnifinGateService } from '../unifin-gate.service';

declare var fernet: any;
const LP_NAME = 'LP Microsoft';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
  key = "7q40HxH6XepENF43zHhsFbu4a7y8SYVonldHZBkBzYc=";
  buttonDisabled = false
  codigo = "7002"
  form: FormGroup;
  error: boolean;
  slideOptions = {
    perPage: 2,
    rewind: true,
    breakpoints: {
      1200: {
        perPage: 2,
      },
      990: {
        perPage: 2,
      },
      780: {
        perPage: 1,
      },
    }
  };

  constructor(private formBuilder: FormBuilder, public httpClient: HttpClient, private unifinService: UnifinGateService) {
    this.buildForm();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.form.reset();
    this.form.setErrors(null)
  }

  buildForm() {
    this.form = this.formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      pat: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z]*$')]),
      mat: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z]*$')]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required, Validators.minLength(10), Validators.pattern('^[0-9]*$')]),
      terms_and_conditions: new FormControl('', Validators.required)
    });
  }

  onInputChange(e) {
    var min = e.target.min,
      max = e.target.max,
      val = e.target.value;

    e.target.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%';
  }

  validateFortyEight(): boolean {
    return this.form.value.active === 'Transporte' || this.form.value.active === 'MA'
      || this.form.value.active === 'AP' || this.form.value.active === 'AP';
  }

  sendToCRM() {
    // this.form.value.terms_and_conditions;
    /* const lead = {
      "origen_medio": LP_NAME,
      "lead": {
        "regimen_fiscal_c": "3",
        "nombre_empresa_c": this.form.value.name,
        "origen_c": "1",
        "detalle_origen_c": "3",
        "medio_digital_c": "16",
        "punto_contacto_c": "2",
        "origen_ag_tel_c": "",
        "promotor_c": "",
        "origen_busqueda_c": "",
        "puesto_c": "",
        "macrosector_c": "",
        "ventas_anuales_c": "",
        "zona_geografica_c": "",
        "email": this.form.value.email,
        "phone_mobile": this.form.value.phone,
        "detalle_plataforma": "",
        "id_landing_c": "",
        "lead_source_c": "",
        "facebook_pixel_c": "",
        "ga_client_id_c": "",
        "keyword_c": "",
        "campana_c": "",
        "compania_c": "1"
      }
    };
    this.unifinService.auth().subscribe((data) => {
      const token = data.token;
      // no crm connection
      this.unifinService.sendLead(lead).subscribe((data) => {
        // console.log("data", data);
      }); */

    this.buttonDisabled = true
    const lead = {
      "origen_medio": "Alianzas de SUMA",
      "codigo_alianza": this.codigo,
      "lead": {
        "regimen_fiscal_c": "2",
        "nombre_c": this.form.value.name || "",
        "apellido_paterno_c": this.form.value.pat || "",
        "apellido_materno_c": this.form.value.mat || "",
        "nombre_empresa_c": "noname",
        "origen_c": "12",
        "detalle_origen_c": "41",
        "medio_digital_c": "",
        "punto_contacto_c": "",
        "origen_ag_tel_c": "",
        "promotor_c": "",
        "origen_busqueda_c": "",
        "puesto_c": "",
        "macrosector_c": "",
        "ventas_anuales_c": "",
        "zona_geografica_c": "",
        "email": this.form.value.email,
        "phone_mobile": this.form.value.phone,
        "phone_home": "",
        "phone_work": "",
        "detalle_plataforma": "",
        "id_landing_c": this.codigo,
        "lead_source_c": "Alianzas de SUMA",
        "facebook_pixel_c": "",
        "ga_client_id_c": "",
        "keyword_c": "",
        "campana_c": "",
        "compania_c": "2"
      },
      "asociados": [
        {
          "regimen_fiscal_c": "2",
          "nombre_c": this.form.value.name || "",
          "apellido_paterno_c": this.form.value.pat || "",
          "apellido_materno_c": this.form.value.mat || "",
          "nombre_empresa_c": "noname",
          "origen_c": "12",
          "detalle_origen_c": "41",
          "medio_digital_c": "",
          "punto_contacto_c": "",
          "origen_ag_tel_c": "",
          "promotor_c": "",
          "origen_busqueda_c": "",
          "puesto_c": "",
          "macrosector_c": "",
          "ventas_anuales_c": "",
          "zona_geografica_c": "",
          "email": this.form.value.email,
          "phone_mobile": this.form.value.phone,
          "detalle_plataforma": "",
          "id_landing_c": this.codigo,
          "lead_source_c": "Alianzas de SUMA",
          "facebook_pixel_c": "",
          "ga_client_id_c": "",
          "keyword_c": "",
          "campana_c": "",
          "compania_c": "2"
        }
      ]
    }
    const secret = new fernet.Secret(this.key)
    const data = JSON.stringify(lead)
    const token = new fernet.Token({ secret: secret })
    const ciphertext = token.encode(data)

    this.unifinService.unileads({ data: ciphertext }).subscribe(
      (data) => {
        location.href = `https://uniclick.com.mx/${this.codigo}`
      },
      (error) => {
        alert("Servicio temporalmente inhabilitado")
        this.buttonDisabled = false
      }
    );

    // unifin gate connection
    /* this.unifinService.createLead(gateLead).subscribe((data) => {
      console.log("data unifin gate", data);
    }) */
  //  });

    /* this.sendInfo().subscribe((response) => {
      console.log(response);
    });

    setTimeout(() => {
      this.error = false;
    }, 500);

    setTimeout(() => {
      window.location.href = "https://uniclick.com.mx/3114";
    }, 2000); */
  }

  /* sendInfo() {
    const form = document.forms['credit-form'];
    return this.httpClient.post("http://nomilinea-prod.us-west-1.elasticbeanstalk.com/php/contact.php", new FormData(form));
  } */
}
