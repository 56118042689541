import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {

  images = [
    '../../assets/img/carrusel-2/footer-1.webp',
    '../../assets/img/carrusel-2/footer-2.webp',
    '../../assets/img/carrusel-2/footer-3.webp',
    '../../assets/img/carrusel-2/footer-4.webp',
    '../../assets/img/carrusel-2/footer-5.webp',
    '../../assets/img/carrusel-2/footer-6.webp',
    '../../assets/img/carrusel-2/footer-7.webp'];

  customOptions: OwlOptions = {
    loop: true,
    dots: false,
    margin: 5,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: false,
    responsive: {
      0: {
        items: 4,
        nav: false
      },
      600: {
        items: 4,
        nav: false
      },
      1000: {
        items: 7,
        nav: false,
      }
    }
  };

  constructor() { }

  ngOnInit(): void {

  }
}
