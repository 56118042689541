<div class="container" style="margin-top: 80px;">
    <owl-carousel-o class="row" [options]="customOptions">
        <ng-container *ngFor="let img of images">
            <ng-template carouselSlide>
                <div class="item">
                    <img class="owl-trabajo-img" [src]="img">
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</div>