<section class="se-cliente-container pt-100" id="se-cliente">
    <div class="container">
        <div class="container">
            <owl-carousel-o class="row" [options]="customOptions">
                <ng-container *ngFor="let img of images">
                    <ng-template carouselSlide>
                        <div class="item">
                            <div class="column item-inner w-100 d-flex justify-content-center align-items-center">
                                <div class="vertical-center">
                                    <img class="row card-img" [src]="img.src">
                                    <p class="row item-text-owl d-flex justify-content-center text-center">{{img.description}}</p>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    </div>

</section>